<template>
  <div class="row">
    <div class="col-3">
      <aside>
        <div class="alert alert-secondary">
          <p class="mb-0 text-center journal-info">
            <span v-text="stock.journal.volume"></span> 卷
            <span v-text="stock.journal.period"></span> 期
          </p>
          <hr />
          <p class="mb-0 text-center journal-info">
            <strong class="text-muted" v-text="stock.journal.publishDateString"></strong>
          </p>
        </div>
        <img
          :src="stock.journal.pickFile.fileUrl"
          class="img-fluid rounded-start"
          :alt="stock.journal.title"
        />
      </aside>
    </div>
    <div class="col-9">
      <div class="content">
        <h1 v-text="stock.journal.title"></h1>
        <div v-for="(category, cateIndex) in stock.categories" :key="cateIndex" class="mt-2">
          <div v-if="hasFiles(category.id)">
            <p class="catename">
              <span class="badge bg-secondary">{{ category.name }}</span>
            </p>
            <div
              v-for="(item, index) in stock.items.filter(o => o.journalCategoryId == category.id)"
              :key="index"
              class="card-paper"
            >
              <div class="card">
                <router-link
                  class="card-body"
                  :to="'/archives/article/' + item.id"
                  :title="item.subject"
                >
                  <p class="paper-title" v-text="item.subject"></p>
                  <p class="paper-title-eng" v-text="item.subjectEng"></p>
                  <p class="paper-author">
                    {{ item.author }} <small class="text-muted" v-text="item.authorEng"></small>
                  </p>
                  <p class="paper-text text-muted">關鍵字：{{ item.keyword }}</p>
                  <p class="paper-text text-muted">Keywords: {{ item.keywordEng }}</p></router-link
                >
              </div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PublicAPI } from '@/api/index'
import AxiosErrMsg from '@/helper/AxiosErr.js'
import stateMerge from 'vue-object-merge'

export default {
  data() {
    return {
      stock: {
        categories: [],
        items: [],
        journal: {
          id: '',
          volume: '',
          period: '',
          title: '',
          publishDateString: '',
          pickFile: {
            fileId: 0,
            functionCode: '',
            fileUrl: '',
            fileSource: ''
          }
        }
      }
    }
  },
  mounted() {
    this.contentList()
  },
  methods: {
    async contentList() {
      let loader = this.$loading.show()
      await PublicAPI.Journal(this.$route.params.code)
        .then(response => {
          stateMerge(this.stock, response.data)
        })
        .catch(err => {
          AxiosErrMsg(err, this.$swal)
        })
      loader.hide()
    },
    hasFiles(cate) {
      return this.stock.items.filter(o => o.journalCategoryId == cate).length > 0
    }
  }
}
</script>

<style>
.journal-info span {
  font-weight: bolder;
  font-size: 3rem;
  color: #3645c7;
}
.journal-info strong {
  display: block;
}
.catename {
  font-size: 1.5rem;
  margin: 0;
  padding-left: 1rem;
}
</style>
