export default function(err, swal) {
  let errText = ''
  switch (err.data?.status) {
    case 400:
      if (err.data.hasOwnProperty('errors')) {
        Object.keys(err.data.errors).forEach(key => {
          errText += err.data.errors[key].join('<br />')
          errText += '<br />'
        })
        break
      }
      errText = err.data.title
      break
    default:
      errText = '連線伺服器發生錯誤'
      break
  }
  swal({
    title: 'Oops! 發生錯誤',
    html: errText,
    icon: 'error'
  })
}
